<template>
<transition name="fadePage" mode="out-in">
<div class="exchangePower">
  <Top-Nav></Top-Nav>
  <div class="common_title">{{$t('menu.dhsl')}}</div>
  <div class="exchange_box">
    <div class="section_one">
      <div class="title"><span>{{$t('menu.dhsl_num')}}</span><i>{{$t('menu.remainingAmount')}}{{remainingAmount}}</i></div>
      <div><input type="number" :min="1" placeholder="0" v-model="exchangeNum" @input="exchangeNum=exchangeNum.replace(/\D/g,'')"></div>
      
      <div class="tip" style="color:red" v-if="!isAllowanceZeed || !isAllowanceUsdt">{{$t('menu.authorizedFirst')}}</div>
      <div class="tip">{{$t('menu.convertibleQuantity')}} {{swapMaxNum}}U</div>
    </div>
    <div class="section_two">
      <div class="title"><span>{{$t('menu.quantityUsed')}}</span></div>
      <div class="content">
        <div class="sec">
          <div class="num_la">{{needZeedNum}}</div>
          <div>{{$t('menu.balance')}}<i class="font_Bebas">{{zeedBalance}}</i></div>
        </div>
        <div class="sec">
          <div class="get_btn"><button :disabled="usdtBtn" :class="{disabled_btn:usdtBtn}">{{$t('menu.grantAuthorization')}}</button></div>
          <div class="get_btn_ed"><button>{{$t('menu.authorized')}}</button></div>
        </div>
      </div>
    </div>
    <div class="section_three">
      <div class="title"><span>{{$t('menu.availableComPower')}}</span><i>{{canGetPower}}</i></div>
    </div>
    <div class="sec_tip">{{$t('menu.grantAuthorizationTip')}}</div>
    <div class="section_four">
      <button :disabled="exchangeBtn" :class="{blue_bac_btn:true,disabled_btn:exchangeBtn}" @click="exchangeP">{{$t('menu.exchange')}}</button>
      <!-- <button :disabled="exchangeBtn" :class="{blue_bac_btn:true,disabled_a:isDisabl_a}" @click="exchangeP">{{$t('menu.exchange')}}</button> -->
    </div>
  </div>
</div>
</transition>
</template>
<script>
import '../../style/base.css';
import '../../style/exchangePower.css';
import TopNav from '../../components/common/TopNav.vue';
// const starPool = require('../../utils/starPool')

/**
 * 240-（4800-我的算力/3）
 * 4800-我的算力/3 < 240，就不能申购了
 */
export default {
  name: 'ExchangePower',
  components:{TopNav},
  data() {
    return {
      exchangeNum:0, //  申购数量(*申购数量限制整数
      needZeedNum:0,//需要使用ZEED数量
      needUsdtNum:0,//需要使用USDT数量
      canGetPower:0,//可获得算力
      // isDisabl_a:false,//禁用a标签
      appointmentBtn:false, //是否禁用预约排队按钮
      canLineBtn:false,  //是否禁用撤销排队按钮
      usdtBtn:false,  //是否禁用授权按钮
      isZeed:0, //授权zeed是否成功，成功为1,失败为0
      isUsdt:0, //授权usdt是否成功，成功为1,失败为0
      swapMinNum: 240,//算力申购最小数量U
      swapMaxNum: 4800,//算力申购最大数量U = （4800-我的算力/3）
      myComputingPower:0,//我的算例
      isAllowanceUsdt:true,//是否授权过Usdt
      isAllowanceZeed:true,//是否授权过Zeed
    }
  },
  mounted(){
    
  },
  
  methods:{
    
  }
}
</script>
<style scoped>

</style>